<template lang="pug">
.col-lg-11.col-12.mx-auto.contact-wrapper
  .row.contact-title(:class="{ 'position-absolute': !isMobileDevice }")
    span contact us
  div(v-if="isMobileDevice")
    modal(name="contact" width="100%" height="100%" classes="contact-modal")
      button.close-btn(@click="$modal.hide('contact')")
        img(src='../img/xclose.png' alt='close')
      wizard(isMobileDevice="true")

    .contact-mobile-wrapper.row
      .mt-3 Take a moment to let us know about you and your project.
      div
        button.btn.btn-primary.mt-3.ml-auto(@click="showModal") Contact us

  wizard(v-else isMobileDevice="true")

</template>

<script>
import axios from "axios";
import Wizard from "src/homepage/components/wizard";

export default {
  components: { Wizard },

  methods: {
    showModal() {
      this.$modal.show('contact');
    }
  },

  computed: {
    isMobileDevice() {
      return navigator.userAgent.match(/(iPhone|iPod|iPad|Android|webOS|BlackBerry|IEMobile|Opera Mini)/i);
    }
  }
};
</script>
