<template lang="pug">
.wizard-form
  form-wizard(color='#FFFFFF')
    tab-content
      .wizard-title
        span(v-if="isMobileDevice") Let's start with your name!
        span(v-else) Take a moment to let us know about you and your project. Let's start with your name!
      form(data-vv-scope="step-1").form-group
        input#name.form-control.vue-form-control(name='name' placeholder='tell us your name' v-model='modelForm.name' @keydown="preventSubmitOnMobile")
    tab-content
      .wizard-title
        span Great, now let's get into the details of your project!
      form.row(data-vv-scope="step-2")
        .form-group.col-md-6
          multiselect(placeholder='Type(s)' :multiple='true' v-model='modelForm.types' name='types' :searchable='false' deselect-label='-' select-label='+' selected-label='+' :options='types')
        .form-group.col-md-6
          multiselect(placeholder='Industry' v-model='modelForm.industry' name='industry' :searchable='false' deselect-label='-' select-label='+' selected-label='+' :options='industry')
    tab-content
      .wizard-title
        span Describe your project in a few words. Are there any other things, or challenges we should consider?
      form(data-vv-scope="step-3")
        .form-group
          textarea#details.form-control.vue-form-control(placeholder='Tell us all about it!' name='message' v-model='modelForm.message' rows='3') Tell us all about it!
    tab-content
      .wizard-title
        span How did you find out about Wolfpack Digital?
      form(data-vv-scope="step-4")
        .form-group
          textarea#source.form-control.vue-form-control(
          placeholder='Tell us the story' name='source' v-model='modelForm.source' rows='3')
    tab-content
      .wizard-title
        span Where can we reach you to get the conversation started?
      form(data-vv-scope="step-5")
        .form-group
          input#email.form-control.vue-form-control(
            name='email' placeholder='business email address*' v-model='modelForm.email'
            v-validate="'required|email'" @keydown="preventSubmitOnMobile"
          )
          span.vue-errors {{ errors.first(&apos;step-5.email&apos;) }}
        .form-group
          input#phone_number.form-control.vue-form-control(
            name='phone_number' placeholder='phone number' v-model='modelForm.phone_number'
            @keydown="preventSubmitOnMobile"
          )
        .form-group
          input.hidden(type="checkbox", v-model='modelForm.terms', name="terms", v-validate="'required'")
          p-check.p-svg.p-curve(name='check' v-model='modelForm.terms')
            img.svg(slot='extra' src='../svg/checkbox-checked.svg' alt="check-terms")
          span.vue-link
            | I agree to the
            a(href='/privacy' target='_blank')  Terms &amp; Conditions &amp; Privacy policy.
            br
          span.vue-errors(v-show="errors.has('step-5.terms')") Please agree to the Terms &amp; Conditions &amp; Privacy policy.
      .form-group
        p-check.p-svg.p-curve(name='check' v-model='mailing')
          img.svg(slot='extra' src='../svg/checkbox-checked.svg' alt="check-mailing")
        span.vue-link Add me to the Wolfpack Digital mailing list.
    tab-content
      .wizard-title
        p.thank-you-for-writing Thank you for writing!
        br
        p.keep-an-eye-on-your Keep an eye on your email, our answer will be there soon.
        p.keep-an-eye-on-your
          | Meanwhile, why not read about our process and how we develop a product start-to-end?
    template(slot='footer' slot-scope='props')
      .wizard-footer-right
        wizard-button(class='pulse', v-if='props.activeTabIndex == 0', @click.native="validateForm('step-1', props)") let&apos;s talk
        wizard-button(class='pulse', v-if='props.activeTabIndex == 1' @click.native="validateForm('step-2', props)") tell us more
        wizard-button(class='pulse', v-if='props.activeTabIndex == 2' @click.native="validateForm('step-3', props)") next
        wizard-button(class='pulse', v-if='props.activeTabIndex == 3' @click.native="validateForm('step-4', props)") continue
        wizard-button(class='pulse', v-if='props.activeTabIndex == 4' @click.native="submit('step-5', props)") let&apos;s talk
        wizard-button(class='pulse', v-if='props.activeTabIndex == 5' onclick="location.href = '/blogposts/the-secret-to-building-an-mvp-that-is-desirable-marketable-delightful-and-lovable#case-studies'") take me there
        .col-lg-1.jumping-wolf-img(v-if='props.activeTabIndex == 5')
          img(src='../img/jumpingwolf.png' alt='jumpingwolf')
</template>

<script>
import axios from "axios";

function initialFormState() {
  return {
    name: "",
    types: [],
    industry: "",
    message: "",
    email: "",
    phone_number: "",
    source: "",
    terms: false,
    lead_type: "homepage"
  };
}

export default {
  props: ["isMobileDevice"],

  data() {
    return {
      modelForm: { ...initialFormState() },
      mailing: false,
      types: ["Web App", "Iphone/Ipad App", "Android App", "Website", "Other"],
      industry: [
        "Fintech",
        "Social/Networking",
        "Healthcare",
        "Automotive",
        "Food/Hospitality",
        "Other"
      ],
      eventLabels: [
        "Let's talk",
        "Tell us more",
        "Next",
        "Continue",
        "Let's talk final"
      ]
    };
  },
  methods: {
    resetFormFields() {
      this.modelForm = { ...initialFormState() };
    },

    preventSubmitOnMobile(event) {
      if (event.keyCode === 13 && this.isMobileDevice) {
        event.preventDefault();
        document.activeElement.blur();
        return true;
      }
    },

    submit(scope, props) {
      this.$validator.validateAll(scope).then(result => {
        if (result) {
          axios
            .post("/api/v1/leads", {
              lead: this.modelForm,
              mailing: this.mailing
            })
            .then(response => {
              if (response.status === 200) {
                props.nextTab();
                this.resetFormFields();
                if (!!window.ga) {
                  window.ga("send", "event", {
                    eventCategory: "CTA",
                    eventAction: "submit-contact-form"
                  });
                }
                this.sendAnalyticsEvent(props.activeTabIndex);
              }
            })
            .catch(e => console.log(e));
        }
      });
    },

    validateForm(scope, props) {
      this.$validator.validateAll(scope).then(result => {
        if (result) {
          props.nextTab();
          this.sendAnalyticsEvent(props.activeTabIndex);
        }
      });
    },

    sendAnalyticsEvent(tabIndex) {
      if (!!window.ga) {
        window.ga("send", "event", {
          eventCategory: "CTA",
          eventAction: "Contact Form Footer",
          eventLabel: this.eventLabels[tabIndex]
        });
      }
    }
  }
};
</script>
