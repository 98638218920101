<template lang="pug">
  #vue-contact-page
    .container
      b-alert.alert-dismissable(:show='showSuccessMessage' variant='success' dismissible)
        | Thank you! You will be redirected to the home page shortly.
      b-alert.error-message(
        :show='showErrorMessage'
        variant='danger'
        dismissible
        @dismissed="showErrorMessage = false"
      )
        | Please fill in all the required fields.
      .row.justify-content-center
        .col-12.col-lg-9
          b-card.px-5
            h1.my-5.pb-lg-2.font-weight-bold Let's build your app
            p.introduction.mb-4.mb-lg-5.pb-lg-2
              | Thank you for stopping by! We are happy that our work resonates with you.
              | Share your idea with us and let’s get things rolling.
              | We like to be quick in answering back and staying committed to your business goals.
            b-form.mt-3(
              label-width='120px'
              label-position='top'
              @submit="submitForm"
            )
              .row
                .col-12
                  b-form-group.mb-lg-5.pb-lg-2
                    label.required Let's start with your name!
                    b-form-input.text-field(
                      :class="{ error: errors.has('name') }"
                      type="text"
                      v-validate="'required'"
                      name="name"
                      v-model='form.name'
                      placeholder='tell us your name'
                    )
                    span(v-show="errors.has('name')" class="error")
                      | {{ errors.first('name') }}
              .row
                .col-12
                  b-form-group.mb-lg-5.pb-lg-2(label="Great! Now let's get into the details of your project:")
                    .row
                      .col-12.col-lg-6
                        el-select.w-100(
                          multiple
                          v-model='form.types'
                          placeholder='type(s)'
                        )
                          el-option(
                            v-for="(type, index) in types"
                            :label='type'
                            :value='type'
                            :key="type.index"
                          )

                      .col-12.col-lg-6
                        el-select.w-100.mt-3.mt-lg-0(v-model='form.industry', placeholder='industry')
                          el-option(
                            v-for="(industry, index) in industries"
                            :label='industry'
                            :value='industry'
                            :key="industry.index"
                          )
              .row
                .col-12
                  b-form-group.mb-lg-5(label='Describe your project in a few words. Are there any other things, or challenges we should consider?')
                    b-form-textarea(
                      placeholder="tell us all about it!"
                      v-model='form.message'
                      rows="5"
                    )

              .row
                .col-12
                  b-form-group.mb-lg-5(label='Please tell us if you have any timeline restrictions?')
                    b-form-textarea(
                      placeholder="tell us your timeline restrictions"
                      v-model='form.timeline_restrictions'
                      rows="5"
                    )

              .row
                .col-12
                  b-form-group.mb-lg-5.pb-lg-2(label="Please tell us your estimated budget")
                    el-select.w-100(v-model='form.budget', placeholder='budget')
                      el-option(
                        v-for="(budget,index) in budgets"
                        :label="budget"
                        :value="budget"
                        :key="budget.index"
                      )
              .row
                .col-12
                  b-form-group.mb-lg-5.pb-lg-2
                    label.required How did you find out about Wolfpack Digital?
                    b-form-textarea(
                      :class="{ error: errors.has('source') }"
                      placeholder="tell us all about it!"
                      v-model='form.source'
                      name="source"
                      v-validate="'required'"
                      rows="5"
                    )
                    span(v-show="errors.has('source')" class="error")
                      | {{ errors.first('source') }}
              .row
                .col-12
                  b-form-group.mb-lg-5.pb-lg-2
                    label.required Your business email address?
                    b-form-input.text-field(
                      :class="{ error: errors.has('email') }"
                      type="text"
                      v-validate="'required|email'"
                      name="email"
                      v-model='form.email'
                      placeholder="business email address"
                    )
                    span(v-show="errors.has('email')" class="error")
                      | {{ errors.first('email') }}

              .row
                .col-12
                  b-form-group.mb-lg-5.pb-lg-2
                    label Your phone number?
                    b-form-input.text-field(
                      :class="{ error: errors.has('phone_number') }"
                      type="tel"
                      name="phone_number"
                      v-model='form.phone_number'
                      placeholder="phone number"
                    )

              .row
                .col-12
                  input.hidden(type="checkbox", v-model='form.terms', name="terms", v-validate="'required'")
                  p-check.p-svg.p-curve(name='check' v-model='form.terms')
                    img.svg(slot='extra' src='../svg/checkbox-checked.svg' alt="check-terms")
                  span
                    | I agree to the
                    a.font-weight-bold.privacy-policy-link(href='/privacy' target='_blank') Terms &amp; Conditions &amp; Privacy policy.
                    br
                  span(v-show="errors.has('terms')" class="error") Please agree to the Terms &amp; Conditions &amp; Privacy policy.

              .row.mt-3
                .col-12
                  b-form-group.mb-lg-5.pb-lg-2
                    p-check.p-svg.p-curve(name='check' v-model='form.newsletter')
                      img.svg(slot='extra' src='../svg/checkbox-checked.svg' alt="check-newsletter")
                    span Add me to the Wolfpack Digital mailing list

              .row
                .col-12
                  b-form-group.float-right.mb-lg-5.pb-lg-2
                    b-button.px-5.font-weight-bold(variant="outline-light", @click="submitForm") let's talk

</template>

<script>
import axios from "axios";
import BForm from "bootstrap-vue/es/components/form/form";
import BFormGroup from "bootstrap-vue/es/components/form-group/form-group";
import BFormInput from "bootstrap-vue/es/components/form-input/form-input";
import BFormSelect from "bootstrap-vue/es/components/form-select/form-select";
import BFormTextarea from "bootstrap-vue/es/components/form-textarea/form-textarea";
import BButton from "bootstrap-vue/es/components/button/button";
import BCard from "bootstrap-vue/es/components/card/card";
import BFormCheckbox from "bootstrap-vue/es/components/form-checkbox/form-checkbox";
import BAlert from "bootstrap-vue/es/components/alert/alert";

import { Select, Option } from "element-ui";

export default {
  components: {
    BAlert,
    BForm,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BFormTextarea,
    BButton,
    BCard,
    BFormCheckbox,
    ElSelect: Select,
    ElOption: Option
  },

  data() {
    return {
      form: {
        name: "",
        types: [],
        industry: "",
        budget: "",
        newsletter: false,
        terms: false,
        email: "",
        phone_number: "",
        source: "",
        message: "",
        timeline_restrictions: "",
        lead_type: "contact_page"
      },
      showSuccessMessage: false,
      showErrorMessage: false,
      types: ["Web App", "iPhone/iPad App", "Android App", "Website", "Other"],
      industries: [
        "Fintech",
        "Social/Networking",
        "Healthcare",
        "Automotive",
        "Food/Hospitality",
        "Entertainment",
        "Real Estate",
        "IoT",
        "Other"
      ],
      budgets: [
        "<10K EUR",
        "10-25K EUR",
        "25-50K EUR",
        "50-100K EUR",
        ">100K EUR",
        "not sure"
      ]
    };
  },
  methods: {
    submitForm() {
      this.$validator.validateAll().then(() => {
        if (!this.errors.any()) {
          axios
            .post("/api/v1/leads", {
              lead: this.form,
              mailing: this.form.newsletter
            })
            .then(response => {
              if (!!window.ga) {
                window.ga("send", "event", {
                  eventCategory: "CTA",
                  eventAction: "submit-contact-form"
                });
                window.ga("send", "event", {
                  eventCategory: "CTA",
                  eventAction: "Contact page",
                  eventLabel: "Let's talk"
                });
              }
              this.showSuccessMessage = true;
              this.scrollToTop();
              this.redirectToHomePage();
            });
        } else {
          this.showErrorMessage = true;
          this.scrollToTop();
        }
      });
    },

    scrollToTop() {
      window.scrollTo(0, 0);
    },

    redirectToHomePage() {
      window.setTimeout(function() {
        window.location.href = "/";
      }, 5000);
    }
  }
};
</script>
