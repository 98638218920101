import "../../src/frontpage.sass";
import Vue from "vue";
import Contact from "../../src/homepage/components/contact.vue";
import ContactPage from "../../src/homepage/components/contact-page.vue";

import VModal from "vue-js-modal";
Vue.use(VModal);

import VueFormWizard from "vue-form-wizard";
Vue.use(VueFormWizard);

import Multiselect from "vue-multiselect";
Vue.component("multiselect", Multiselect);

import VeeValidate from "vee-validate";
import { Validator } from "vee-validate";

const dictionary = {
  custom: {
    source: {
      required: "Please tell us how you heard about us"
    }
  }
};

Vue.use(VeeValidate, {
  classes: true,
  fieldsBagName: "formFields",
  classNames: {
    valid: "is-valid",
    invalid: "is-invalid"
  }
});

Validator.localize("en", dictionary);

import PrettyCheckbox from "pretty-checkbox-vue";
Vue.use(PrettyCheckbox);

import TurbolinksAdapter from "vue-turbolinks";
Vue.use(TurbolinksAdapter);

document.addEventListener("turbolinks:load", () => {
  var element = document.getElementById("vue-contact-form");
  if (!!element) {
    new Vue({
      el: "#vue-contact-form",
      template: "<Contact/>",
      components: { Contact }
    });
  }
});

document.addEventListener("turbolinks:load", () => {
  var element = document.getElementById("vue-contact-page");
  if (!!element) {
    new Vue({
      el: "#vue-contact-page",
      template: "<ContactPage/>",
      components: { ContactPage }
    });
  }
});
